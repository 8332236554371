import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ApiOrder } from '../../api/interfaces';
import { ContentfulEcomCommon } from '../../contentful';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import {
  searchApiOrders,
  selectCustomerNumber,
  setOrderHistoryEmpty,
} from '../../store/slices';
import { OrderHistoryModal } from '../order-history-modal';
import { OrderHistoryRow } from '../order-histoy-row';
import { RichText } from '../rich-text';
import * as styles from './order-history.module.scss';

interface OrderHistoryProps {
  ecomCommon: ContentfulEcomCommon;
}
export const OrderHistory = ({ ecomCommon }: OrderHistoryProps) => {
  const dispatch = useAppDispatch();

  const customerNumber = useAppSelector((state: RootState) =>
    selectCustomerNumber(state),
  );

  const [orderHistory, setOrderHistory] = useState<ApiOrder[]>();
  const [viewOrderHistory, setViewOrderHistory] = useState<ApiOrder[]>();
  const [fetchNumberOfOrders, setFetchNumberOfOrders] = useState<number>(30);
  const [orderHistoryFilterString, setOrderhistoryFilterString] =
    useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<ApiOrder | null>(null);

  const handleModalClose = (): void => {
    setSelectedOrder(null);
  };

  useEffect(() => {
    const getOrderHistory = async () => {
      if (customerNumber) {
        const orderHistoryResult = await dispatch(
          searchApiOrders({
            customerNumber,
            numberOfOrders: fetchNumberOfOrders,
          }),
        ).unwrap();

        if (orderHistoryResult) {
          setOrderHistory([...orderHistoryResult]);
          setViewOrderHistory([...orderHistoryResult]);
        } else {
          setOrderHistory([]);
          setViewOrderHistory([]);
        }
      }
    };

    getOrderHistory();

    return () => {
      dispatch(setOrderHistoryEmpty());
    };
  }, [customerNumber, dispatch, fetchNumberOfOrders]);

  const filterOrderHistory = (filterValue: string): void => {
    setOrderhistoryFilterString(filterValue);

    const filteredOrderHistory = orderHistory?.filter((order) => {
      const orderNumberString: string = order.orderId
        ? order.orderId.toString(10)
        : '';

      const containsOrderNumber = orderNumberString
        .toLowerCase()
        .includes(filterValue.toLowerCase());

      const containsOrderReference = order.customerReferenceNumber
        ? order.customerReferenceNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        : false;

      return containsOrderNumber || containsOrderReference;
    });

    setViewOrderHistory(filteredOrderHistory);
  };

  const onClickGetMore = (): void => {
    setFetchNumberOfOrders(() => fetchNumberOfOrders + 30);
  };

  return (
    <>
      <h1>{ecomCommon.orderHistoryPage.title}</h1>
      <RichText
        data={{
          richText: {
            raw: ecomCommon.orderHistoryPage.intro.raw,
            references: [],
          },
        }}
      />

      <form className={styles.filterForm}>
        <FormControl fullWidth={true}>
          <TextField
            label={ecomCommon.orderHistoryPage.filterFieldLabel}
            autoFocus={true}
            autoComplete="false"
            className={styles.filterField}
            placeholder={
              ecomCommon.orderHistoryPage.orderHistoryFilterFieldPlaceholder
            }
            InputProps={{
              inputProps: {
                className: styles.filterFieldInput,
              },
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              filterOrderHistory(event.target.value)
            }
            value={orderHistoryFilterString}
          ></TextField>
        </FormControl>
      </form>
      <Card sx={{ boxShadow: 3, p: 0, mb: 5 }}>
        {viewOrderHistory && viewOrderHistory.length > 0 && (
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.headerCell}>
                  {
                    ecomCommon.orderHistoryPage
                      .orderHistoryTableOrderNumberLabel
                  }
                </TableCell>
                <TableCell className={styles.headerCell}>
                  {
                    ecomCommon.orderHistoryPage
                      .orderHistoryTableOrderReferenceLabel
                  }
                </TableCell>
                <TableCell className={styles.headerCell}>
                  {ecomCommon.orderHistoryPage.orderHistoryTableOrderDateLabel}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewOrderHistory.map((order, index) => (
                <OrderHistoryRow
                  onClick={() => setSelectedOrder(order)}
                  key={`${order.orderId}${index}`}
                  order={order}
                ></OrderHistoryRow>
              ))}
            </TableBody>
          </Table>
        )}
        {orderHistoryFilterString !== '' &&
          viewOrderHistory &&
          viewOrderHistory.length === 0 && (
            <CardContent>
              {
                ecomCommon.orderHistoryPage
                  .orderHistoryTableOrderFilterNoResults
              }
            </CardContent>
          )}
        {orderHistoryFilterString === '' &&
          viewOrderHistory &&
          viewOrderHistory.length === 0 && (
            <CardContent>
              {
                ecomCommon.orderHistoryPage
                  .orderHistoryTableOrderHistoryNoResults
              }
            </CardContent>
          )}
      </Card>
      {viewOrderHistory && viewOrderHistory.length >= 30 && (
        <Box className={styles.showMoreWrapper}>
          <Button size="large" variant="outlined" onClick={onClickGetMore}>
            {ecomCommon.orderHistoryPage.orderHistoryTableLoadMoreButton}
          </Button>
        </Box>
      )}
      <OrderHistoryModal
        handleClose={handleModalClose}
        order={selectedOrder}
        ecomCommon={ecomCommon}
      />
    </>
  );
};
