import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { ApiOrder } from '../../api/interfaces';
import { ContentfulEcomCommon } from '../../contentful';
import { CartItem } from '../../store/interfaces';
import { CartArticleList } from '../cart-article-list';
import * as styles from './order-history-modal.module.scss';

interface OrderHistoryModalProps {
  order: ApiOrder | null;
  handleClose: () => void;
  ecomCommon: ContentfulEcomCommon;
}
export const OrderHistoryModal = ({
  order,
  handleClose,
  ecomCommon,
}: OrderHistoryModalProps) => {
  const getDeliveryDateAndTime = (order: ApiOrder | null): string => {
    let returnValue = '';

    if (order) {
      const deliveryDate = order.deliveryDate;
      const unloadingTime = order.unloadingTime;

      if (deliveryDate && unloadingTime) {
        returnValue = `${deliveryDate}, ${unloadingTime}`;
      } else if (deliveryDate && !unloadingTime) {
        returnValue = deliveryDate;
      } else if (!deliveryDate && unloadingTime) {
        returnValue = unloadingTime;
      }
    }

    return returnValue;
  };

  const getCartItems = (order: ApiOrder | null): CartItem[] => {
    if (order) {
      return order.orderLines
        ? order.orderLines.map((orderLine) => {
            return {
              articleNumber: orderLine.articleNumber,
              articleQuantityAndAreaExplanation:
                orderLine.articleQuantityAndAreaExplanation,
              articleSalesUnit: orderLine.articleSalesUnit,
              articleTitle: orderLine.articleTitle,
              quantity: orderLine.quantity,
            };
          })
        : [];
    }
    return [];
  };

  return (
    <Dialog
      open={order !== null}
      onClose={handleClose}
      aria-labelledby="order-history-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <Button
        onClick={handleClose}
        sx={{ position: 'absolute', top: 12, right: 12 }}
      >
        {ecomCommon.orderHistoryPage.orderHistoryDialogCloseButtonLabel}
      </Button>
      <DialogTitle id="order-history-title">
        {ecomCommon.orderHistoryPage.orderHistoryDialogTitle}
      </DialogTitle>
      <DialogContent>
        <p>
          {ecomCommon.orderHistoryPage.orderHistoryDialogOrderNumberLabel}{' '}
          {order?.orderId}
        </p>
        <Box className={styles.OrderHistoryModalBodyContent}>
          <Box className={styles.OrderHistoryModalBodyContentColumn}>
            <Box component="h3">
              {ecomCommon.orderHistoryPage.orderHistoryDialogCustomerLabel}
            </Box>
            <span>{order?.receiverName}</span>
            <span>{order?.customerName}</span>
            <span>{order?.customerAddressStreet}</span>
            <span>
              {order?.customerAddressZip}, {order?.customerAddressCity}
            </span>

            <Box component="h4" className={styles.OrderHeading}>
              {
                ecomCommon.orderHistoryPage
                  .orderHistoryDialogCustomerNumberLabel
              }
            </Box>
            <span>{order?.customerNumber}</span>

            <Box component="h4" className={styles.OrderHeading}>
              {ecomCommon.labelOrderReference}
            </Box>
            <span>{order?.customerReferenceNumber}</span>
          </Box>
          <Box className={styles.OrderHistoryModalBodyContentColumn}>
            <Box component="h3" sx={{ mb: 0 }}>
              {ecomCommon.checkoutPage.titleDelivery}
            </Box>
            <Box component="h4" className={styles.OrderHeading}>
              {ecomCommon.orderHistoryPage.orderHistoryDialogDeliveryTimeLabel}
            </Box>
            <span>{getDeliveryDateAndTime(order)}</span>

            <Box component="h4" className={styles.OrderHeading}>
              {ecomCommon.checkoutPage.unloading.title}
            </Box>
            <span>{order?.unloadingAlternative}</span>
            <span>{order?.unloadingInstructions}</span>

            <Box component="h4" className={styles.OrderHeading}>
              {ecomCommon.checkoutPage.deliveryAddress.title}
            </Box>
            <span>{order?.deliveryAddressStreet}</span>
            <span>
              {order?.deliveryAddressZip}, {order?.deliveryAddressCity}
            </span>

            <Box component="h4" className={styles.OrderHeading}>
              {ecomCommon.checkoutPage.deliveryRecipient.title}
            </Box>
            <span>
              {order?.receiverName}, {order?.receiverMobileNumber}
            </span>

            <Box component="h4" className={styles.OrderHeading}>
              {ecomCommon.orderHistoryPage.orderHistoryDialogMessageLabel}
            </Box>
            <span>{order?.messageToCustomerService}</span>
          </Box>
        </Box>
        <Box className={styles.orderInstructionsSection}>
          <Box component="h3">
            {ecomCommon.checkoutPage.deliveryAddress.labelDriverInstructions}
          </Box>
          <p>{order?.instructionToDriver}</p>
        </Box>
        <CartArticleList
          cartItems={getCartItems(order)}
          ecomCommon={ecomCommon}
          changeCartItemQuantityActionCreator={() => {
            /*dummy*/
          }}
          cartIsEditable={false}
        />
      </DialogContent>
    </Dialog>
  );
};
