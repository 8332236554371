import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { ContentfulSettings } from '../contentful';
import { Helmet } from 'react-helmet';
import { OrderHistory } from '../components/order-history';

interface OrderHistoryPageQueryProps {
  contentfulSettings: ContentfulSettings;
}
type OrderHistoryPageType = PageProps<OrderHistoryPageQueryProps>;
const OrderHistoryPage = ({ data }: OrderHistoryPageType) => {
  return (
    <>
      <Helmet
        title={data.contentfulSettings.ecomCommon.orderHistoryPage.title}
      />
      <OrderHistory ecomCommon={data.contentfulSettings.ecomCommon} />
    </>
  );
};

export const data = graphql`
  query {
    contentfulSettings {
      ecomCommon {
        ...EcomCommon
      }
    }
  }
`;

export default OrderHistoryPage;
