import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { ApiOrder } from '../../api/interfaces';
import * as styles from './order-history-row.module.scss';

interface OrderHistoryRowProps {
  order: ApiOrder;
  onClick: () => void;
}
export const OrderHistoryRow = ({ order, onClick }: OrderHistoryRowProps) => {
  const formatDate = (dateTimeString: string | undefined) =>
    dateTimeString && dateTimeString.split('T')[0];

  return (
    <TableRow className={styles.OrderHistoryRow} onClick={onClick}>
      <TableCell>{order.orderId}</TableCell>
      <TableCell>{order.customerReferenceNumber}</TableCell>
      <TableCell>{formatDate(order.created)}</TableCell>
    </TableRow>
  );
};
